<template>
  <span>
    <!-- <DxGrid
      :canAdd="false"
      :isEditable="false"
      :data-source="dataSource"
      @on-delete="(v) => onDelete(v)"
    >
      <template #columns>
        <DxColumn data-field="user_id" caption="Name" />
        <DxColumn data-field="remarks" caption="Remarks" />
        <DxColumn data-field="action_datetime" caption="Date/Time" />
      </template>
    </DxGrid> -->

    <DxDataGrid
      key-expr="id"
      :data-source="dataSource"
      :focused-row-enabled="true"
      :allow-column-reordering="true"
      :allow-column-resizing="true"
      :column-auto-width="true"
      :show-borders="true"
      :row-alternation-enabled="true"
      @row-removed="handleEvent('row-removed', $event)"
    >
      <DxColumn type="buttons">
        <DxButton name="delete" />
      </DxColumn>

      <DxColumn data-field="user_id" caption="Name" />
      <DxColumn data-field="remarks" caption="Remarks" />
      <DxColumn data-field="action_datetime" caption="Date/Time" />

      <DxColumnChooser :enabled="true" />
      <DxColumnFixing :enabled="true" />
      <DxSorting mode="multiple" />
      <DxFilterRow :visible="true" />
      <DxGroupPanel :visible="true" />
      <DxGrouping :auto-expand-all="false" />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel location="before" :visible="true" />

      <DxToolbar>
        <DxItem location="before" name="columnChooserButton" />
        <DxItem location="before" name="searchPanel" />
        <DxItem name="groupPanel" />

        <DxItem v-if="dataSource.length === 0" template="addButtonTemplate" />
      </DxToolbar>

      <template #addButtonTemplate>
        <div>
          <b-button variant="primary">
            <feather-icon icon="RefreshCwIcon" />
          </b-button>
        </div>
      </template>
    </DxDataGrid>
  </span>
</template>

<script>
import timelineHeaderApi from '@api/timeline_headers';
import timelineApi from '@api/timelines';
import { queryParameters } from '@/schema';
// @todo change from utils classes to global-function
import { unixToDisplayDateTime } from '@/utils/classes/dates';

export default {
  name: 'CompanyCommentsComponent',
  props: {
    headerId: {
      type: Number,
      default: 0,
    },
    isActive: {
      type: Number,
      default: 0,
    },
  },
  components: {},
  watch: {
    isActive(v) {
      if (v === 5) {
        this.loadTimelineHeader();
      }
    },
  },
  data: () => ({
    dataColumns: [],
    dataSource: [],
  }),
  mounted() {
    if (+this.$route.query.id) {
      this.loadTimelineHeader();
    }
  },
  methods: {
    onDelete(data) {
      console.log(data);
    },
    loadTimelineHeader() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
        filterExt: `header_id=${this.headerId}`,
      };

      timelineHeaderApi
        .list(params)
        .then(({ data }) => {
          if (data && data.length > 0) {
            this.loadTimelines(data[0].id);
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadTimelines(id) {
      const params = {
        sort: '-created_at',
        page: queryParameters.page,
        status: queryParameters.status,
        filterExt: `header=${id}`,
      };

      timelineApi
        .list(params)
        .then(({ data }) => {
          for (const item of data) {
            item.action_datetime = unixToDisplayDateTime(item.updated_at);
          }

          this.dataSource = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    handleEvent(event) {
      console.log(event);
    },
  },
};
</script>

<style></style>
