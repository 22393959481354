import api from '@apiInstance';

export default {
  add(types) {
    return api.post('company_attachments', types);
  },
  get(id) {
    return api.fetch(`company_attachments/${id}`);
  },
  list(params) {
    return api.fetch('/company_attachments', { params });
  },
  update(types) {
    return api.patch('company_attachments', types);
  },
  delete(id) {
    return api.remove('company_attachments', id);
  },
};
