<template>
  <span>
    <b-row>
      <b-col>
        <b-form-group>
          <DxDataGrid
            key-expr="id"
            :data-source="dataSource"
            :focused-row-enabled="true"
            :allow-column-reordering="true"
            :allow-column-resizing="true"
            :column-auto-width="true"
            :show-borders="true"
            :row-alternation-enabled="true"
            @init-new-row="handleEvent('init-new-row', $event)"
            @option-changed="handleEvent('option-changed', $event)"
            @row-removed="handleEvent('row-removed', $event)"
          >
            <DxPaging :page-size="totalCount" />

            <DxEditing
              :allow-updating="!isDisabled"
              :allow-deleting="!isDisabled"
              :use-icons="!isDisabled"
              mode="row"
            />

            <DxColumn v-if="!isDisabled" type="buttons">
              <DxButton icon="edit" :onClick="editItem" />
              <DxButton icon="file" :onClick="onRevise" />

              <DxButton name="delete" />
            </DxColumn>

            <DxColumnChooser :enabled="true" />
            <DxColumnFixing :enabled="true" />
            <DxSorting mode="multiple" />
            <DxFilterRow :visible="true" />
            <DxGroupPanel :visible="true" />
            <DxGrouping :auto-expand-all="false" />
            <DxHeaderFilter :visible="true" />
            <DxSearchPanel location="before" :visible="true" />

            <DxColumn
              v-for="(item, index) in dataColumns"
              :key="index"
              :data-field="item.field"
              :caption="item.caption"
            />

            <DxToolbar>
              <DxItem location="before" name="columnChooserButton" />
              <DxItem location="before" name="searchPanel" />
              <DxItem name="groupPanel" />

              <DxItem v-if="!isDisabled" template="addButtonTemplate" />
            </DxToolbar>

            <template #addButtonTemplate>
              <div>
                <b-button
                  variant="primary"
                  class="text-capitalize"
                  @click="addModal = true"
                >
                  Add
                </b-button>
              </div>
            </template>
          </DxDataGrid>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-nav>
          <b-nav-item
            @click="onSetPage(page)"
            v-for="(page, index) in pageSize"
            :key="index"
          >
            <span :class="page.isActive ? 'text-success' : ''">
              {{ page.name }}
            </span>
          </b-nav-item>
        </b-nav>
      </b-col>
      <b-col>
        <b-pagination
          align="right"
          v-model="currentPage"
          :total-rows="totalCount"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
        />
      </b-col>
    </b-row>

    <b-modal
      v-model="addModal"
      centered
      size="lg"
      title="Add Company Document"
      @hide="closeModal()"
      no-close-on-backdrop
    >
      <b-row>
        <b-col>
          <h2>Document</h2>
        </b-col>
      </b-row>

      <b-row v-if="formDetail.name">
        <b-col cols="12" md="6">
          <b-form-group label="Name" label-for="v-name">
            <b-form-input disabled id="v-name" v-model="formDetail.name" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Uploaded by" label-for="v-user_id">
            <b-form-input
              disabled
              id="v-user_id"
              v-model="formDetail.user_id"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="File Type" label-for="v-file_type">
            <b-form-select
              id="v-file_type"
              v-model="formDetail.file_type"
              :options="documentTypes"
              @change="onSelectFileType($event)"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Revision No." label-for="v-revision_no">
            <b-form-input
              disabled
              id="v-revision_no"
              class="text-right"
              v-model="formDetail.to_revise"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group label="Description" label-for="v-description">
            <b-form-textarea
              id="v-description"
              v-model="formDetail.description"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Upload File" label-for="v-file-upload">
            <b-form-file
              v-model="onFileUpload"
              :accept="acceptedFile"
              placeholder="Choose/Drop a file here..."
              drop-placeholder="Drop a file here..."
              id="v-file-upload"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- <b-row v-if="formDetail.base64 || formDetail.file">
        <b-col class="text-center">
          <b-form-group>
            <b-img
              v-if="getFileType !== 'pdf'"
              style="margin-bottom: 5px"
              height="250"
              width="250"
              thumbnail
              fluid
              :src="getImageSource"
            />

            <iframe
              v-if="getFileType === 'pdf'"
              style="margin-bottom: 5px"
              height="250"
              width="250"
              thumbnail
              fluid
              :src="getImageSource"
            />

            <br />
            <b-button
              @click="onFileRemove"
              type="button"
              variant="outline-danger"
              size="sm"
            >
              Remove
            </b-button>
          </b-form-group>
        </b-col>
      </b-row> -->

      <!-- eslint-disable -->
      <template #modal-footer="{ close }">
        <b-button @click="closeModal()" variant="danger" class="bg-darken-4">
          Cancel
        </b-button>
        <b-button @click="onSubmit" variant="primary" class="bg-darken-4">
          Save
        </b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import companyAttachmentsApi from '@api/company_attachments';
import documentTypesApi from '@api/document_types';
import { queryParameters } from '@/schema';
// @todo change from utils classes to global-function
import { UtilsDate } from '@/utils/classes/dates';

export default {
  name: 'CompanyDocumentsComponent',
  props: {
    headerId: {
      type: Number,
      default: 0,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    dataSource(v) {
      for (let i = 0; i < v.length; i++) {
        const e = v[i];

        const toDate = new UtilsDate(e.updated_at);
        e.upload_date = toDate.unixToDate();
      }
    },
    onFileUpload(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.formDetail.name = file.name;
          this.formDetail.file = file.name;
          this.formDetail.base64 = reader.result;

          this.fileExtension = file.type;
        };
      }
    },
  },
  computed: {
    getImageSource: {
      get() {
        if (+this.$route.query.id) {
          if (this.formDetail.base64) {
            return this.formDetail.base64;
          }

          if (!this.formDetail.base64 && this.formDetail.file) {
            return this.formDetail.file;
          }
        }

        return this.formDetail.base64;
      },
    },
    getFileType: {
      get() {
        if (this.fileExtension && this.fileExtension.includes('/')) {
          return this.fileExtension.split('/')[1];
        }
      },
    },
  },
  data: () => ({
    dataIndex: -1,
    currentPage: 1,
    perPage: 0,
    totalCount: 0,
    page: 0,
    size: 0,
    pageSize: [
      { name: '10', value: 10, isActive: false },
      { name: '20', value: 20, isActive: false },
      { name: '50', value: 50, isActive: false },
      { name: '100', value: 100, isActive: false },
      { name: 'ALL', value: 10000, isActive: false },
    ],
    isShowingMap: false,
    addModal: false,
    onFileUpload: null,
    fileExtension: null,
    dataSource: [],
    documentTypes: [],
    acceptedFile: '*',
    dataColumns: [
      { caption: 'Name', field: 'name' },
      { caption: 'File Type', field: 'file_type' },
      { caption: 'Upload Date', field: 'upload_date' },
      { caption: 'Upload By', field: 'user_id' },
      { caption: 'Revision No.', field: 'revision_no' },
    ],
    formDetail: {
      id: 0,
      header_id: 0,
      name: null,
      icon: null,
      file: null,
      file_type: null,
      revision_no: 1,
      to_revise: 1,
      description: null,
      status: 'O',

      base64: null,
    },
  }),
  watch: {
    page(v) {
      this.loadDocuments();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadDocuments();
      }
    },
    currentPage(v) {
      this.page = v;
    },
  },
  mounted() {
    this.onSetPage({ value: queryParameters.page.size });
    this.loadDocuments();
    this.loadDocumentTypes();
  },
  methods: {
    loadDocuments() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
        filterExt: `header=${this.headerId}`,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      companyAttachmentsApi
        .list(params)
        .then(({ data, meta }) => {
          for (const item of data) {
            const toDate = new UtilsDate(item.updated_at);
            item.upload_date = toDate.unixToDate();
          }

          this.dataSource = data;

          this.totalCount = meta.page.total;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadDocumentTypes() {
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000 },
        status: queryParameters.status,
      };

      documentTypesApi
        .list(params)
        .then(({ data }) => {
          const items = [];
          for (const item of data) {
            const { name, file_type, extension, icon } = item;
            items.push({
              text: `${name} - ${file_type}`,
              value: file_type,
              extension,
              icon,
            });
          }

          this.documentTypes = items;
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    closeModal() {
      this.addModal = false;
      const defaultForm = {
        id: 0,
        header_id: 0,
        name: null,
        icon: null,
        file: null,
        file_type: null,
        revision_no: 1,
        to_revise: 1,
        description: null,
        status: 'O',

        base64: null,
      };

      this.dataIndex = -1;
      this.onFileRemove();
      this.formDetail = defaultForm;
    },
    handleEvent(action, event) {
      if (action === 'row-removed') {
        companyAttachmentsApi.delete(event.key);
      }
    },
    editItem(event) {
      if (event) {
        this.addModal = true;

        const data = event.row.data;
        data.to_revise = data.revision_no;

        this.dataIndex = event.row.dataIndex;
        this.formDetail = data;
      }
    },
    previewItem(event) {
      //
    },
    onSubmit() {
      this.formDetail.header_id = parseInt(this.headerId);
      this.formDetail.revision_no = this.formDetail.to_revise;

      let app;
      if (this.dataIndex > 0) {
        app = companyAttachmentsApi.update(this.formDetail);
      } else {
        if (this.formDetail.to_revise > 0) {
          delete this.formDetail.id;
          delete this.formDetail.user_id;
          delete this.formDetail.created_at;
          delete this.formDetail.updated_at;
        }

        app = companyAttachmentsApi.add(this.formDetail);
      }

      app
        .then(({ data }) => {
          this.closeModal();
          this.loadDocuments();
          this.toastConfig();
        })
        .catch(() => {
          this.toastConfig('', 'error');
        })
        .finally(() => {
          //
        });
    },
    onSelectFileType(event) {
      const selectedFileType = this.documentTypes.find((x) => {
        return x.value === event;
      });

      if (selectedFileType) {
        const { icon, extension } = selectedFileType;
        this.acceptedFile = extension;
        this.formDetail.icon = icon;
      }
    },
    onFileRemove() {
      this.onFileUpload = null;
      this.formDetail.attachment = null;
      this.formDetail.base64 = null;
    },
    onRevise(event) {
      if (event) {
        this.addModal = true;
        this.dataIndex = -1;
        const data = event.row.data;
        data.to_revise = data.revision_no + 1;

        this.formDetail = data;
      }
    },
    onSetPage(event) {
      for (let i = 0; i < this.pageSize.length; i++) {
        const page = this.pageSize[i];

        page.isActive = event.value === page.value;
      }

      this.size = event.value;
      this.perPage = event.value;
    },
  },
};
</script>

<style lang="scss"></style>
