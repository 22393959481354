<template>
  <span>
    <b-row>
      <b-col>
        <b-form @submit.prevent="onSubmit">
          <b-row>
            <b-col>
              <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label="Tag"
                label-for="v-tag"
              >
                <b-form-input
                  v-model="formDetail.tag"
                  id="v-tag"
                  placeholder="#..."
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label="Note"
                label-for="v-notes"
              >
                <quill-editor
                  id="v-notes"
                  ref="quillEditorRef"
                  v-model="content"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label="Upload (max. 3 files)"
                label-for="v-upload"
              >
                <b-form-file
                  ref="formFileRef"
                  accept="*"
                  v-model="onFileUpload"
                  placeholder="Choose/Drop a file here..."
                  drop-placeholder="Drop a file here..."
                  id="onFileUploadCompanyNotes"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="getImageSource">
            <b-col class="text-center">
              <b-form-group
                label-cols="4"
                label-cols-lg="2"
                label=""
                label-for="v-uploaded_files"
              >
                <b-row>
                  <b-col v-for="(item, index) in getImageSource" :key="index">
                    <div class="">
                      <b-img
                        height="150"
                        width="150"
                        thumbnail
                        fluid
                        :src="item.base64"
                      />
                    </div>
                    <div class="">
                      {{ item.name }}
                    </div>
                    <div class="">
                      <b-button
                        @click="onFileRemove(index)"
                        type="button"
                        variant="outline-danger"
                        size="sm"
                      >
                        Remove
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col class="text-right">
              <b-button type="submit" variant="primary"> Submit </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
    <hr />

    <b-row>
      <b-col>
        <h2>Information Trail</h2>
      </b-col>
      <b-col class="text-right">
        <b-form-group>
          <b-input-group size="sm" class="input-group-merge">
            <b-form-input
              v-model="keyword"
              id="Search"
              placeholder="Search..."
            />
            <b-input-group-append is-text>
              <feather-icon icon="SearchIcon" class="cursor-pointer" />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-for="item in dataSource" :key="item.id" class="mb-3">
      <b-col>
        <div class="">
          <strong>
            {{ item.tag }}
          </strong>
          <span>
            <b-button
              type="button"
              variant="flat-danger"
              size="sm"
              @click="onRemove(item)"
            >
              remove
            </b-button>
          </span>
        </div>
        <div class="">
          <span class="pl-2 pr-1 text-success" style="display: inline-block">
            <feather-icon class="mb-2" icon="CheckCircleIcon" size="24" />
          </span>
          <span style="display: inline-block">
            <div>
              {{ displayDateTimeFromUnix(item.updated_at) }} by
              {{ item.user_id }}
            </div>
            <div v-html="item.notes" />
          </span>
          <div>
            <b-img
              style="height: 150px !important; width: 150px !important"
              class="cursor-pointer mr-1"
              thumbnail
              fluid
              v-for="image in item.company_note_attachments.data"
              :key="image.id"
              :src="getImage(image)"
              @click="openAttachment(image)"
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </span>
</template>

<script>
import companyNotesApi from '@api/company_notes';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { queryParameters } from '@/schema';

export default {
  name: 'CompanyNotesComponent',
  props: {
    headerId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    onFileUpload(file) {
      if (file) {
        if (this.formDetail.uploads.length > 2) {
          const data = {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `Max 3 files`,
              icon: 'XIcon',
              variant: 'danger',
              text: `you have uploaded more than 3 files`,
            },
          };

          this.$toast(data);
          return;
        }

        if (this.formDetail.uploads.length <= 3) {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const data = {
              name: file.name,
              base64: reader.result,
            };

            const isExisting = this.formDetail.uploads.find((x) => {
              return x.name === file.name;
            });

            if (!isExisting) {
              this.formDetail.uploads.push(data);
            }
          };
        }
      }
    },
    keyword(v) {
      this.loadCompanyNotes();
    },
  },
  computed: {
    getImageSource: {
      get() {
        if (this.headerId) {
          const formDetail = this.formDetail;
          if (formDetail.uploads.length > 0) {
            return formDetail.uploads;
          }
        }
      },
    },
  },
  data: () => ({
    keyword: null,
    content: null,
    onFileUpload: null,
    dataSource: [],
    items: [],
    formDetail: {
      id: 0,
      header_id: 0,
      tag: null,
      notes: null,
      uploads: [],
    },
  }),
  methods: {
    loadCompanyNotes() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
        filterExt: `header_id=${this.headerId}`,
      };

      if (this.keyword) {
        params.filter = this.keyword;
      }

      companyNotesApi
        .list(params)
        .then(({ data }) => {
          this.dataSource = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onSubmit() {
      let app;
      this.formDetail.notes = this.content;
      this.formDetail.header_id = parseInt(this.headerId);

      if (!this.formDetail.id) {
        app = companyNotesApi.add(this.formDetail);
      } else {
        app = companyNotesApi.update(this.formDetail);
      }

      app
        .then(({ data }) => {
          this.content = null;
          this.onFileUpload = null;

          const msg = `Site: ${this.formDetail.tag} success save`;
          this.toastConfig(msg);

          this.formDetail = {
            id: 0,
            header_id: 0,
            tag: null,
            notes: null,
            uploads: [],
          };

          this.loadCompanyNotes();
        })
        .catch(() => {
          this.toastConfig('', 'error');
        })
        .finally(() => {
          //
        });
    },
    onFileRemove(index) {
      this.onFileUpload = null;
      this.formDetail.uploads.splice(index, 1);
    },
    onRemove(data) {
      console.log(data);
    },
    getImage(item) {
      return `${process.env.VUE_APP_ROOT_STATIC_URL}/company_notes_attachments/${item.id}/${item.file}`;
    },
    openAttachment(item) {
      const url = `${process.env.VUE_APP_ROOT_STATIC_URL}/company_notes_attachments/${item.id}/${item.file}`;
      window.open(url, '_blank');
    },
  },
};
</script>

<style>
p {
  margin: 0 0 0 0;
}
</style>
