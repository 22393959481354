<template>
  <div class="p-1 text-center">
    <b-button
      v-b-modal.modal-center
      class="mr-1"
      variant="primary"
      size="sm"
      @click="status = 'A'"
    >
      Approve
    </b-button>
    <b-button
      v-b-modal.modal-center
      class="mr-1"
      variant="success"
      size="sm"
      @click="status = 'S'"
    >
      Submit
    </b-button>
    <b-button
      v-b-modal.modal-center
      variant="danger"
      size="sm"
      @click="status = 'R'"
    >
      Reject
    </b-button>

    <Modal @onChange="(v) => $emit('onChangeStatus', status)" />
  </div>
</template>

<script>
export default {
  name: 'CompanyApprovalComponent',
  components: {},
  data: () => ({
    status: 'O',
  }),
};
</script>

<style></style>
