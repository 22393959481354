<template>
  <span>
    <b-card>
      <b-row>
        <b-col cols="12" md="10">
          <b-form-group>
            <feather-icon
              @click="onShowSearch = !onShowSearch"
              icon="SearchIcon"
              class="cursor-pointer"
            />
            <strong style="margin-left: 10px">Customer Insint Panel</strong>
          </b-form-group>
        </b-col>
        <b-col class="text-right">
          <b-form-group>
            <strong @click="$emit('close-panel', false)" class="closePanel">
              x
            </strong>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="onShowSearch">
        <b-col>
          <b-form-group>
            <b-input-group size="sm" class="input-group-merge">
              <b-form-input
                v-model="keyword"
                id="Search"
                placeholder="Search..."
              />
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" class="cursor-pointer" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </span>
</template>

<script>
export default {
  name: 'CompanyInsightPanel',
  data: () => ({
    keyword: null,
    onShowSearch: false,
  }),
  methods: {
    onSearch() {},
  },
};
</script>

<style>
.closePanel:hover {
  color: #ea5455;
  cursor: pointer;
}
</style>
