<template>
  <span>
    <b-row>
      <b-col>
        <DxDataGrid
          key-expr="id"
          :data-source="dataSource"
          :focused-row-enabled="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :row-alternation-enabled="true"
          @init-new-row="handleEvent('init-new-row', $event)"
          @option-changed="handleEvent('option-changed', $event)"
          @row-removed="handleEvent('row-removed', $event)"
        >
          <DxEditing
            :allow-updating="!isDisabled"
            :allow-deleting="!isDisabled"
            :allow-adding="!isDisabled"
            :use-icons="!isDisabled"
            mode="row"
          />

          <DxColumn v-if="!isDisabled" type="buttons">
            <DxButton icon="edit" :onClick="editItem" />
            <DxButton name="delete" />
          </DxColumn>

          <DxColumnChooser :enabled="true" />
          <DxColumnFixing :enabled="true" />
          <DxSorting mode="multiple" />
          <DxFilterRow :visible="true" />
          <DxGroupPanel :visible="true" />
          <DxGrouping :auto-expand-all="false" />
          <DxHeaderFilter :visible="true" />
          <DxSearchPanel location="before" :visible="true" />

          <DxColumn
            v-for="(item, index) in fields"
            :key="index"
            :data-field="item.field"
            :caption="item.caption"
            :cell-template="item.template"
          />

          <DxToolbar>
            <DxItem location="before" name="columnChooserButton" />
            <DxItem location="before" name="searchPanel" />
            <DxItem name="groupPanel" />

            <DxItem v-if="getRole" template="addButtonTemplate" />
          </DxToolbar>

          <template #addButtonTemplate>
            <div>
              <b-button
                variant="primary"
                class="text-capitalize"
                @click="addModal = true"
              >
                Add
              </b-button>
            </div>
          </template>
        </DxDataGrid>
      </b-col>
    </b-row>

    <b-modal
      v-model="addModal"
      centered
      size="lg"
      title="Add Company Contact"
      @hide="closeModal()"
      no-close-on-backdrop
    >
      <Alert :error="errors" />

      <b-row>
        <b-col>
          <h2>Contact</h2>
        </b-col>
      </b-row>

      <validation-observer ref="companyContactRef">
        <b-row>
          <b-col>
            <b-form-group label="Title" label-for="v-title">
              <b-form-select
                id="v-title"
                v-model="formDetail.title"
                :options="courtesyTitles"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Firstname" label-for="v-first_name">
              <b-form-input v-model="formDetail.first_name" id="v-first_name" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Lastname" label-for="v-last_name">
              <b-form-input v-model="formDetail.last_name" id="v-last_name" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Desgination" label-for="v-designation">
              <b-form-select
                v-model="formDetail.designation"
                :options="designations"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Departments" label-for="v-department">
              <v-select
                id="v-department"
                v-model="formDetail.department"
                :options="departments"
                :reduce="(department) => department.value"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Email" label-for="v-email">
              <template v-slot:label>
                Email <small class="text-danger">*</small>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-input
                  id="v-email"
                  v-model="formDetail.email"
                  name="v-email"
                  :state="errors.length > 0 ? false : null"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Mobile" label-for="v-mobile">
              <b-form-input v-model="formDetail.mobile" id="v-mobile" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Telephone" label-for="v-tel">
              <b-form-input v-model="formDetail.tel" id="v-tel" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <h2>Account</h2>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-form-group label="Username" label-for="v-username">
              <b-form-input
                :disabled="disableField"
                v-model="formDetail.username"
                id="v-username"
              />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="User Type" label-for="v-user_type">
              <b-form-select
                :disabled="disableField"
                v-model="formDetail.user_type"
                :options="userTypes"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <span v-if="!formDetail.id">
          <b-row>
            <b-col>
              <b-form-group label="Password" label-for="v-password">
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required|password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="v-password"
                      v-model="password"
                      :type="password1FieldType"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      name="v-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password1ToggleIcon"
                        @click="togglePassword1Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label-for="v-password_confirm"
                label="Confirm Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Confirm Password"
                  vid="confirm_password"
                  rules="required|confirmed:password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="v-password_confirm"
                      v-model="confirm_password"
                      :type="password2FieldType"
                      class="form-control-merge"
                      :state="errors.length > 0 ? false : null"
                      name="v-password_confirm"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="password2ToggleIcon"
                        @click="togglePassword2Visibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </span>
      </validation-observer>

      <!-- eslint-disable -->
      <template #modal-footer="{ close }">
        <b-button @click="closeModal()" variant="danger" class="bg-darken-4">
          Cancel
        </b-button>
        <b-button
          :disabled="getValidation()"
          @click="onSubmit"
          variant="primary"
          class="bg-darken-4"
        >
          Save
        </b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import companyContactsApi from '@api/company_contacts';
import designationsApi from '@api/designations';
import departmentsApi from '@api/departments';
import { queryParameters } from '@/schema';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import { required, email } from '@validations';
import md5 from 'md5';

import { getUserData } from '@/auth/utils';

export default {
  name: 'CompanyContactsComponent',
  mixins: [togglePasswordVisibility],
  props: {
    dataSource: {
      type: Array,
      default: [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    headerId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    getRole() {
      const userData = getUserData();
      const { role } = userData;

      const roles = ['admin', 'custadmin', 'qcsemployee'];
      return roles.includes(role) && !this.isDisabled;
    },
    disableField: {
      get() {
        return Boolean(parseInt(this.formDetail.id));
      },
    },
  },
  watch: {
    dataSource(v) {
      for (let i = 0; i < v.length; i++) {
        const element = v[i];
        const { first_name, last_name, designation, user_type } = element;
        const { user } = element;

        element.full_name = first_name;

        if (last_name) {
          element.full_name += ` ${last_name}`;
        }

        if (user && user.data) {
          element.username = user.data.username;
        }

        if (user_type) {
          const d = this.userTypes.find((x) => {
            return x.value === user_type;
          });

          element.preview_user_type = d.text;
        }
      }
    },
  },
  data: () => ({
    errors: null,
    required,
    email,
    dataIndex: -1,
    addModal: false,
    designations: [],
    departments: [],
    courtesyTitles: ['Mr.', 'Mrs.', 'Ms.', 'Miss', 'Dr.', 'Prof.'],
    userTypes: [
      { text: 'Customer Admin', value: 'custadmin' },
      { text: 'Customer User', value: 'custuser' },
    ],
    fields: [
      { caption: 'Username', field: 'username' },
      { caption: 'Staff Name', field: 'full_name' },
      { caption: 'Designation', field: 'designation' },
      { caption: 'User Type', field: 'preview_user_type' },
    ],
    formDetail: {
      id: 0,
      header_id: 0,
      username: null,
      password: null,
      user_id: null,
      user_type: 'custuser',
      email: null,
      title: null,
      first_name: null,
      last_name: null,
      mobile: null,
      tel: null,
      designation: '',
      departments: '',
      status: 'O',
    },
    password: null,
    confirm_password: null,
    password1FieldType: 'password',
    password2FieldType: 'password',
  }),
  created() {
    this.loadDepartments();
    this.loadDesignations();
  },
  methods: {
    loadDesignations() {
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000 },
        status: queryParameters.status,
      };

      designationsApi
        .list(params)
        .then(({ data }) => {
          const items = [];
          data.forEach((item) => {
            const i = {
              value: item.name,
              text: item.description,
            };

            items.push(i);
          });

          this.designations = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadDepartments() {
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000 },
        status: queryParameters.status,
      };

      departmentsApi
        .list(params)
        .then(({ data }) => {
          const items = [];
          for (const item of data) {
            items.push({ label: item.description, value: item.name, item });
          }

          this.departments = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    closeModal() {
      this.addModal = false;
      this.password = null;
      this.confirm_password = null;

      const defaultForm = {
        id: 0,
        header_id: this.headerId,
        username: null,
        password: null,
        confirm_password: null,
        user_id: null,
        user_type: 'custuser',
        email: null,
        first_name: null,
        last_name: null,
        mobile: null,
        tel: null,
        designation: '',
        department: '',
        status: 'O',
      };

      this.$nextTick(() => {
        this.dataIndex = -1;
        this.formDetail = Object.assign({}, defaultForm);
      });
    },
    handleEvent(action, event) {
      if (action === 'row-removed') {
        companyContactsApi.delete(event.key);
      }
    },
    editItem(event) {
      if (event) {
        this.addModal = true;
        this.isSavingDisabled = false;
        this.$nextTick(() => {
          const data = event.row.data;

          this.dataIndex = event.row.dataIndex;
          this.formDetail = Object.assign({}, data);
        });
      }
    },
    previewItem(event) {
      //
    },
    onSubmit() {
      this.formDetail.header_id = this.headerId;
      this.formDetail.password = this.password ? md5(this.password) : '';

      this.$refs.companyContactRef.validate().then((success) => {
        let app;

        if (success) {
          if (this.formDetail.id) {
            app = companyContactsApi.update(this.formDetail);
          } else {
            app = companyContactsApi.add(this.formDetail);
          }

          if (app) {
            app
              .then(({ data }) => {
                const { first_name, last_name, designation, user_type } =
                  this.formDetail;

                data.full_name = first_name;
                if (data.last_name) {
                  data.full_name += ` ${last_name}`;
                }

                if (designation) {
                  const d = this.designations.find((x) => {
                    return x.value === designation;
                  });

                  element.preview_designation = d.text;
                }

                if (user && user.data) {
                  element.username = user.data.username;
                }

                if (user_type) {
                  const d = this.designations.find((x) => {
                    return x.value === user_type;
                  });

                  element.preview_user_type = d.text;
                }

                if (this.dataIndex > -1) {
                  this.formDetail.id = data.id;
                  this.dataSource.splice(this.dataIndex, 1);
                  this.dataSource.splice(this.dataIndex, 0, this.formDetail);
                } else {
                  this.dataSource.push(this.formDetail);
                }

                this.closeModal();
              })
              .catch(({ errors }) => {
                if (errors) {
                  const [err] = errors;
                  this.toastConfig(err.title, 'error');

                  const { meta } = errors[0];
                  const email = meta ? meta['Email.Unique'] : '';
                  const username = meta ? meta['Username.Unique'] : '';
                  this.errors = {
                    response: {
                      data: { errors: [{ title: email ? email : username }] },
                    },
                  };
                } else {
                  if (this.dataIndex > -1) {
                    this.dataSource.splice(this.dataIndex, 1);
                    this.dataSource.splice(this.dataIndex, 0, this.formDetail);
                  } else {
                    this.dataSource.push(this.formDetail);
                  }

                  this.closeModal();
                }
              })
              .finally(() => {
                //
              });
          }
        }
      });
    },
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === 'password' ? 'text' : 'password';
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === 'password' ? 'text' : 'password';
    },
    getValidation() {
      const ref = this.$refs.companyContactRef;

      if (ref) {
        const { errors } = ref;
        if (errors) {
          const { confirm_password, email, password } = errors;
          if (
            (password && password.length > 0) ||
            (confirm_password && confirm_password.length > 0) ||
            (email && email.length > 0)
          ) {
            return true;
          }

          return false;
        }
      }
    },
  },
};
</script>

<style lang="scss"></style>
