<template>
  <span>
    <DxGrid
      :canAdd="false"
      :isEditable="false"
      :data-source="dataSource"
      @on-delete="(v) => onDelete(v)"
    >
      <template #columns>
        <DxColumn data-field="user_id" caption="Name" />
        <DxColumn data-field="action" caption="Actions" />
        <DxColumn data-field="action_datetime" caption="Date/Time" />
      </template>
    </DxGrid>
  </span>
</template>

<script>
import auditLogApi from '@api/audit_logs';
// @todo change from utils classes to global-function
import { unixToDisplayDateTime } from '@/utils/classes/dates';
import { queryParameters } from '@/schema';

export default {
  name: 'CompanyLogsComponent',
  props: {
    headerId: {
      type: Number,
      default: 0,
    },
    isActive: {
      type: Number,
      default: 0,
    },
  },
  components: {},
  watch: {
    isActive(v) {
      if (v === 4) {
        this.loadLogs();
      }
    },
  },
  data: () => ({
    dataSource: [],
    dataColumns: [],
  }),
  mounted() {
    if (this.headerId) {
      this.loadLogs();
    }
  },
  methods: {
    loadLogs() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.filterExt = `header_id=${this.headerId},model='Company'`;

      auditLogApi
        .list(params)
        .then(({ data }) => {
          data.forEach((e) => {
            e.action = e.log.action;
            e.action_datetime = unixToDisplayDateTime(e.updated_at);
          });

          this.dataSource = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onDelete(data) {
      //
    },
  },
};
</script>

<style></style>
