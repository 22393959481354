<template>
  <span>
    <b-row>
      <b-col>
        <DxDataGrid
          key-expr="id"
          :data-source="dataSource"
          :focused-row-enabled="true"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-borders="true"
          :row-alternation-enabled="true"
          @init-new-row="handleEvent('init-new-row', $event)"
          @option-changed="handleEvent('option-changed', $event)"
          @row-removed="handleEvent('row-removed', $event)"
        >
          <DxEditing
            :allow-updating="!isDisabled"
            :allow-deleting="!isDisabled"
            :allow-adding="!isDisabled"
            :use-icons="!isDisabled"
            mode="row"
          />

          <DxColumn v-if="!isDisabled" type="buttons">
            <DxButton icon="check" :onClick="onSetDefault" />
            <DxButton icon="edit" :onClick="editItem" />
            <DxButton name="delete" />
          </DxColumn>

          <DxColumnChooser :enabled="true" />
          <DxColumnFixing :enabled="true" />
          <DxSorting mode="multiple" />
          <DxFilterRow :visible="true" />
          <DxGroupPanel :visible="true" />
          <DxGrouping :auto-expand-all="false" />
          <DxHeaderFilter :visible="true" />
          <DxSearchPanel location="before" :visible="true" />

          <DxColumn
            v-for="(item, index) in fields"
            :key="index"
            :data-field="item.field"
            :caption="item.caption"
            :cell-template="item.template"
            :width="item.width"
          />

          <DxToolbar>
            <DxItem location="before" name="columnChooserButton" />
            <DxItem location="before" name="searchPanel" />
            <DxItem name="groupPanel" />

            <DxItem v-if="!isDisabled" template="addButtonTemplate" />
          </DxToolbar>

          <template #addButtonTemplate>
            <div>
              <b-button
                variant="primary"
                class="text-capitalize"
                @click="
                  addModal = true;
                  loadCompanyContacts();
                "
              >
                Add
              </b-button>
            </div>
          </template>
        </DxDataGrid>
      </b-col>
    </b-row>

    <b-modal
      v-model="addModal"
      centered
      size="lg"
      @hide="closeModal()"
      no-close-on-backdrop
    >
      <template #modal-title>
        <b-form-checkbox
          checked="false"
          name="check-button"
          switch
          inline
          v-model="formDetail.is_default"
        >
          Default Location
        </b-form-checkbox>
      </template>

      <b-row>
        <b-col>
          <h2>Location</h2>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Site PTC">
            <v-select
              label="name"
              v-model.number="formDetail.site_contact_id"
              :options="siteContacts"
              :reduce="(siteContacts) => +siteContacts.id"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" md="3">
          <b-form-group label="Site ID" label-for="v-site_id">
            <b-form-input
              maxlength="60"
              v-model="formDetail.site_id"
              id="v-site_id"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="9">
          <b-form-group label="Site Name" label-for="v-site_name">
            <b-form-input
              maxlength="60"
              v-model="formDetail.site_name"
              id="v-site_name"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group label="Location Type" label-for="v-site_type">
            <b-form-input
              maxlength="60"
              v-model="formDetail.site_type"
              id="v-site_type"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Site Description" label-for="v-description">
            <b-textarea
              rows="4"
              v-model="formDetail.site_description"
              id="v-description"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Building/Block/House No. and Street Name"
            label-for="v-addr1"
          >
            <b-input-group class="input-group-merge">
              <b-form-input
                maxlength="60"
                v-model="formDetail.addr1"
                id="v-addr1"
              />
              <b-input-group-append
                class="cursor-pointer"
                is-text
                @click="isShowingMap = !isShowingMap"
              >
                <feather-icon class="pin" icon="MapPinIcon" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Unit No. and Building Name" label-for="v-addr2">
            <b-form-input
              maxlength="60"
              v-model="formDetail.addr2"
              id="v-addr2"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Country" label-for="v-company_name">
            <v-select
              v-model="formDetail.country"
              :options="$store.getters['hbaseGeneral/getAllCountries']"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Postal" label-for="v-postal">
            <b-form-input
              maxlength="60"
              v-model="formDetail.postal"
              id="v-postal"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <ModalMap
            :showMap="isShowingMap"
            @onChangePlace="(v) => onGetPlace(v)"
          />
        </b-col>
      </b-row>

      <!-- eslint-disable -->
      <template #modal-footer="{ close }">
        <b-button @click="closeModal()" variant="danger" class="bg-darken-4">
          Cancel
        </b-button>
        <b-button @click="onSubmit" variant="primary" class="bg-darken-4">
          Save
        </b-button>
      </template>
    </b-modal>
  </span>
</template>

<script>
import companyLocationsApi from '@api/company_locations';
import companyContactsApi from '@api/company_contacts';
import { queryParameters } from '@/schema';

export default {
  name: 'CompanyLocationsComponent',
  props: {
    dataSource: {
      type: Array,
      default: [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    headerId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    dataSource(v) {
      for (let i = 0; i < v.length; i++) {
        const element = v[i];
        element.address = this.getAddress(element);
      }
    },
  },
  data: () => ({
    dataIndex: -1,
    isShowingMap: false,
    addModal: false,
    fields: [
      { caption: '', field: 'is_default', width: 75 },
      { caption: 'Location ID', field: 'site_id' },
      { caption: 'Location', field: 'address' },
      { caption: 'Location Type', field: 'site_type' },
      { caption: 'PTC (Staff)', field: 'contact_name' },
    ],
    formDetail: {
      id: 0,
      header_id: 0,
      site_contact_id: 0,
      site_id: null,
      site_name: null,
      site_description: null,
      addr1: null,
      addr2: null,
      country: 'Singapore',
      postal: null,
      is_default: false,
    },
    siteContacts: [{ id: 0, name: 'N/A' }],
  }),
  mounted() {
    //
  },
  methods: {
    loadCompanyContacts() {
      const params = {
        sort: queryParameters.sort,
        page: 1000,
        status: queryParameters.status,
        filterExt: `header_id=${this.headerId}`,
      };

      companyContactsApi
        .list(params)
        .then(({ data }) => {
          if (data != null) {
            this.siteContacts = [];
            data.forEach((contact) => {
              const { id, username, first_name, last_name } = contact;
              const item = {
                id: parseInt(id),
                name: `${first_name} ${last_name}`,
              };

              this.siteContacts.push(item);
            });
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    closeModal() {
      this.addModal = false;
      this.isShowingMap = false;
      const defaultForm = {
        id: 0,
        header_id: 0,
        site_contact_id: 0,
        site_id: null,
        site_name: null,
        site_description: null,
        addr1: null,
        addr2: null,
        country: 'Singapore',
        postal: null,
        is_default: false,
      };

      this.$nextTick(() => {
        this.dataIndex = -1;
        this.formDetail = Object.assign({}, defaultForm);
      });
    },
    handleEvent(action, event) {
      if (action === 'row-removed') {
        companyLocationsApi.delete(event.key);
      }
    },
    editItem(event) {
      if (event) {
        this.loadCompanyContacts();
        this.addModal = true;
        this.$nextTick(() => {
          const data = event.row.data;

          this.dataIndex = event.row.dataIndex;
          this.formDetail = Object.assign({}, data);
        });
      }
    },
    previewItem(event) {
      //
    },
    onSubmit() {
      this.formDetail.header_id = this.headerId;

      let app;
      if (this.formDetail.id) {
        app = companyLocationsApi.update(this.formDetail);
      } else {
        app = companyLocationsApi.add(this.formDetail);
      }

      if (app) {
        app
          .then(({ data }) => {
            data.address = this.getAddress(data);
            if (this.dataIndex > -1) {
              this.dataSource.splice(this.dataIndex, 1);
              this.dataSource.splice(this.dataIndex, 0, this.formDetail);
            } else {
              this.dataSource.push(this.formDetail);
            }

            const msg = `Site: ${this.formDetail.site_name} success save`;
            this.toastConfig(msg);
            this.closeModal();
            this.$emit('on-load', true);
          })
          .catch(({ errors }) => {
            const [err] = errors;
            this.toastConfig(err.title, 'error');
          })
          .finally(() => {
            //
          });
      }
    },
    onSetDefault(event) {
      const { data } = event.row;
      const { header_id, id, site_id } = data;

      companyLocationsApi
        .setDefault(header_id, id)
        .then(() => {
          this.toastConfig(`Default set to: ${site_id}`);
          this.$emit('on-load', true);
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    onGetPlace(place) {
      const { name, country, postal, lat, lng } = place;

      this.formDetail.addr1 = name;
      this.formDetail.country = country;
      this.formDetail.postal = postal;
      this.formDetail.latitude = parseFloat(lat || 0);
      this.formDetail.longitude = parseFloat(lng || 0);
    },
    getAddress({ addr1, addr2, country, postal }) {
      let address = '';

      if (addr1) {
        address += addr1;
      }

      if (addr2) {
        address += ` ${addr2}`;
      }

      if (country) {
        address += ` ${country}`;
      }

      if (postal) {
        address += ` ${postal}`;
      }

      return address;
    },
  },
};
</script>

<style lang="scss"></style>
